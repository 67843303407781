import React, { useState, useEffect, useCallback } from 'react';

import { Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { verificationConfiguration } from 'helper/utility';
import PropTypes from 'prop-types';
import { parentNodeMapper, rootNode } from 'views/V2/Integration/constants';
import {
    createChildNodes,
    createEdgesBetweenNodes,
    createGroupNode,
    createParentNode,
} from 'views/V2/Integration/utils';

import styles from '../passCriteriaPreview.module.css';

import { ReactComponent as ExpandIcon } from 'asset/IconsPack/at_expand-01-filled.svg';
import { ReactComponent as MinimizeIcon } from 'asset/IconsPack/at_minimize-01-filled.svg';

import FlowChart from './FlowChart';

function PassCriteriaCanvas({ data, currentWorkflow, maxScreen, setMaxScreen }) {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const handleOpenPreview = () => {
        setMaxScreen(!maxScreen);
    };

    const controlIconsStyle = maxScreen ? styles.maxControlIcon : styles.controlIcons;
    const canvasWrapperStyle = maxScreen ? styles.fullWrapper : styles.fixedWrapper;

    const reGenerateChart = useCallback(() => {
        // filter parent nodes based on their children in the data
        const verificationMethod = verificationConfiguration({
            workflow_config: { id_verifications_obj: currentWorkflow?.id_verifications },
        });
        const parentNodes = Object.keys(parentNodeMapper).filter((parentId) =>
            parentNodeMapper[parentId].children.some((child) => data.includes(child))
        );

        const newNodes = [];
        const childEdges = [];

        parentNodes.forEach((node, index) => {
            const groupId = `group-${node}`;
            const parentId = `parent-${node}`;
            const childId = `child-${node}`;

            const group = createGroupNode(node, index);
            const parent = createParentNode(node, groupId);
            const children = createChildNodes(node, groupId, childId, data);
            const edges = createEdgesBetweenNodes(node, parentId, children);

            newNodes.push(group, parent, ...children);
            childEdges.push(...edges);
        });

        // Create edges between parent groups
        const newEdges = parentNodes.slice(0, -1).map((item, index) => ({
            id: `edge-${item}-to-${parentNodes[index + 1]}`,
            source: `group-${item}`,
            target: `group-${parentNodes[index + 1]}`,
            type: verificationMethod?.toLowerCase() || 'and',
            markerEnd: { type: 'arrow', width: 20, height: 20, color: '#9E77ED' },
        }));

        setNodes([rootNode, ...newNodes]);
        setEdges([...newEdges, ...childEdges]);
    }, [data, currentWorkflow]);

    useEffect(() => {
        reGenerateChart();
    }, [reGenerateChart]);

    return (
        <Box className={styles.passCriteriaContainer}>
            <IconButton
                className={clsx(styles.controlIconsWrapper, styles.maxScreenControl)}
                onClick={handleOpenPreview}
            >
                {maxScreen ? (
                    <MinimizeIcon className={controlIconsStyle} />
                ) : (
                    <ExpandIcon className={controlIconsStyle} />
                )}
            </IconButton>
            <Box className={canvasWrapperStyle}>
                <FlowChart
                    reGenerateChart={reGenerateChart}
                    nodes={nodes}
                    edges={edges}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    maxScreen={maxScreen}
                />
            </Box>
        </Box>
    );
}

PassCriteriaCanvas.propTypes = {
    maxScreen: PropTypes.bool,
    setMaxScreen: PropTypes.func,
    data: PropTypes.array,
    currentWorkflow: PropTypes.object,
};

export default PassCriteriaCanvas;
